import {userService} from "../services/user";
import { config } from "../config/config";

export const helpers = {
    getNewToken,
    getPhotoUrl
};

async function getNewToken(token) {
    let response = await userService.renewToken(token);

    if (response.status === 200 && response.data?.data?.token) {
        return response.data.data;
    }

    throw new Error(response.status);
}

export function getPhotoUrl(img) {
    if (!img) {
        return img;
    }

    if (img.indexOf('https://') >= 0 || img.indexOf('http://') >= 0) {
        return img;
    }

    return config.domain.imgDomain + img;
}

export function playNotifySound() {
    const element = document.getElementById('sound');
    if (element) {
        element.play();
    }
}